import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import React from "react";
import "leaflet/dist/leaflet.css";
import icon from "../icon";
import L from "leaflet";

import marker from '../../assets/icons/icons-32-x-32-export-maps-filled.svg';
import Icon from "../icon"
import image from "../../assets/images/modal/modal-header-image.jpg";

const position = [41.5803685, 2.0200456]

const Map = () => {

  if(typeof window === "undefined"){
    return;
  }

  const myIcon = new L.Icon({
      iconUrl: marker,
      iconRetinaUrl: marker,
      popupAnchor:  [-0, -0],
      iconSize: [32,45]
  });

  return (
    <div style={{ height: '560px', width: '100%' }}>
    <MapContainer center={position} zoom={16} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
        url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png?api_key=95fef62a-2e17-4020-a73a-ad198230838e" /*@TODO: add to .env*/
      />
      <Marker
        position={[41.5803685, 2.0200456]}
        icon={myIcon}
       >
        <Popup>
          <div>
          <div className="image-map">
            <img src={image} alt="Fundació Privada Goel" />
          </div>
          <p>Fundació Privada Goel</p>
          <p><small>Avinguda de Béjar 299, 08226 Terrassa</small></p>
          </div>
        </Popup>
      </Marker>
    </MapContainer>
    </div>
  );
}

export default Map
